import axios from 'axios';

export const GOBAL_IVA = 1.12;

export const GOBAL_IVA_FACTOR = 0.12;
// const BASE_URL = 'http://compuservices-facturacion-back.test/';
// const BASE_URL = 'https://quevedmarket-28326787e790.herokuapp.com/';
//
const BASE_URL = 'https://suc1.quevedmarket.com/';
//
const API = axios.create({
  baseURL: BASE_URL
});

export default API;
